import { Trans } from '@lingui/react/macro'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import { useUserContext } from '@src/User'

const LoginWebView = () => {
  const { typography } = useTypographyContext()
  const { logout } = useUserContext()

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: spacing.normal,
      }}
    >
      <ErrorOutlineIcon css={{ height: 48, width: 48 }} />
      <Trans>There was an error, please try again.</Trans>
      <div css={{ display: 'flex', gap: spacing.base }}>
        <button
          onClick={() => window.location.reload()}
          css={{
            width: 100,
            paddingBlock: spacing.base,
            background: colors.neutrals[50],
            border: `1px solid ${colors.neutrals[50]}`,
            borderRadius: constants.borderRadius.medium,
            color: colors.neutrals[500],
            cursor: 'pointer',
            ...typography.button.small,
            ':hover': {
              borderColor: colors.neutrals[400],
            },
            ':active': {
              background: colors.white[900],
              borderColor: colors.white[900],
              color: colors.neutrals[50],
            },
          }}
        >
          <Trans>Reload</Trans>
        </button>
        <button
          onClick={() => logout()}
          css={{
            width: 100,
            paddingBlock: spacing.base,
            background: colors.neutrals[50],
            border: `1px solid ${colors.neutrals[50]}`,
            borderRadius: constants.borderRadius.medium,
            color: colors.neutrals[500],
            cursor: 'pointer',
            ...typography.button.small,
            ':hover': {
              borderColor: colors.neutrals[400],
            },
            ':active': {
              background: colors.white[900],
              borderColor: colors.white[900],
              color: colors.neutrals[50],
            },
          }}
        >
          <Trans>Logout</Trans>
        </button>
      </div>
    </div>
  )
}

export default LoginWebView
