import { useLingui } from '@lingui/react'
import { t } from '@lingui/core/macro'
import Link from 'next/link'
import { useRouter } from 'next/router'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Logo from '@src/Layout/Logo'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { colors, constants, spacing } from '@src/Styles'
import { useUserContext } from '@src/User'
import { PAGES, shouldShowPageForLocation } from '@src/Layout/helpers'
import LayoutButton from '@src/Layout/LayoutButton'
import { useWonders } from '@src/Fetch/helpers'

const LayoutNavbar = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void
}) => {
  const router = useRouter()
  const { i18n } = useLingui()
  const { isAdmin, me, r_number, user } = useUserContext()

  const { data: restaurant } = useWonders(
    router.pathname === '/l/[loginToken]' ||
      r_number == null ||
      user.token == null
      ? null
      : ['restaurant_info'],
  )

  return (
    <AppBar component="nav" sx={{ background: colors.primary[500] }}>
      <Toolbar
        disableGutters
        sx={{
          height: 64,
          background: colors.primary[500],
          boxShadow: constants.boxShadows.navbar,
          paddingInline: `${spacing.normal}px`,
        }}
      >
        <Link href={'/'} shallow={true}>
          <div css={{ width: 211, height: 64, paddingBlock: 22 }}>
            <Logo />
          </div>
        </Link>

        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end',
              gap: `${spacing.base}px`,
            }}
          >
            {PAGES.filter((p) => p.showInNavigation && p.showInNavBar).map(
              (page) => {
                if (
                  shouldShowPageForLocation({
                    page,
                    restaurant,
                  }) === false
                )
                  return null
                if (me == null && page.isPublic === false) return null
                if (page.label == null) return null
                if (page.adminOnly && !isAdmin) return null

                return (
                  <Link
                    key={page.path}
                    href={page.path}
                    css={{
                      display: 'flex',
                      textDecoration: 'none',
                    }}
                    shallow={true}
                  >
                    <LayoutButton
                      variant="dark"
                      isSelected={router.route === page.path}
                      label={i18n._(page.label)}
                    />
                  </Link>
                )
              },
            )}
          </Box>
        </Box>

        <div css={{ paddingLeft: spacing.comfy }}>
          <IconButton
            color="inherit"
            aria-label={t`open drawer`}
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutNavbar
