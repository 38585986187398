;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d242229322cf16be1675dc2784ba301c186a2d41"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import json from './package.json'
import { environment } from '@/environment'

const release =
  environment === 'development'
    ? 'development'
    : process.env.NEXT_PUBLIC_IMAGE_TAG

Sentry.init({
  enabled: environment !== 'development',
  debug: environment === 'development',
  dsn: 'https://0c7880b5061468166bd621f86f35cce6@o4505109655519232.ingest.sentry.io/4505925399805952',
  environment,
  release,
  initialScope: {
    tags: { 'greendot-wasm': json.dependencies['@kjt01/greendot-wasm'] },
  },

  integrations: [
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/.*/],
      networkRequestHeaders: ['X-Request-Id'],
    }),
  ],

  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Failed to fetch',
    'Task buffer full',
    'No price found for dish item',
    'Dish variant item not found',
    'No dish found with this wid',
    'No enterprise selected',
    'Failed to setup location and customer',
    'No parent modifier found in the cart with this ID',
    'The variant that is owed to this customer no longer exists at this restaurant',
    'TypeError: Load failed',
  ],

  tracesSampleRate: environment === 'production' ? 1 : 0,

  beforeSend(event) {
    const parsedEvent = JSON.parse(JSON.stringify(event))
    window.postMessage({ type: 'sentry', event: parsedEvent })
    return event
  },

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: environment === 'production' ? 1 : 0,
})
