import { CsbRestaurantInfo } from '@kjt01/greendot-wasm'
import { msg } from '@lingui/core/macro'

export const PAGES = [
  {
    label: msg`Orders`,
    path: '/',
    showInNavigation: true,
    showInNavBar: true,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Daily Report`,
    path: '/daily-report',
    showInNavigation: true,
    showInNavBar: true,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Help`,
    path: '/help',
    showInNavigation: true,
    showInNavBar: true,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: msg`Menu Management`,
    path: '/menu-management',
    showInNavigation: true,
    showInNavBar: true,
    adminOnly: true,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Driver Order + Payment`,
    path: '/driver-management',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Invoices`,
    path: '/invoices',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Pay Now`,
    path: '/pay-now',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Pay Your Balance`,
    path: '/pay-now/[params]',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: msg`Transaction History`,
    path: '/pay-now/transactions',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Terms & Agreement`,
    path: '/bank-accounts/terms-and-agreement',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Set Up Bank Information`,
    path: '/bank-accounts/add',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Account Information`,
    path: '/bank-accounts/[abaid]',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Set Up Bank Information`,
    path: '/bank-accounts/[abaid]/edit',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Credit Card Reports`,
    path: '/credit-card-report',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Daily CC Report`,
    path: '/credit-card-report/daily',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Monthly CC Statement`,
    path: '/credit-card-report/monthly',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Restaurant Management`,
    path: '/restaurant-management',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Address Management`,
    path: '/address-management',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
  {
    label: msg`Preferences`,
    path: '/preferences',
    showInNavigation: true,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: msg`Restaurants`,
    path: '/restaurants',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: true,
    isPublic: false,
    requiresLocation: false,
  },
  {
    label: null,
    path: '/terms-of-use',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: null,
    path: '/privacy-policy',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: null,
    path: '/account',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: null,
    path: '/l/[loginToken]',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: true,
    requiresLocation: false,
  },
  {
    label: null,
    path: '/orders/[ticketNumber]/delivery-info',
    showInNavigation: false,
    showInNavBar: false,
    adminOnly: false,
    isPublic: false,
    requiresLocation: true,
  },
]

export const shouldShowPageForLocation = ({
  page,
  restaurant,
}: {
  page: { path: string }
  restaurant: CsbRestaurantInfo | undefined
}) => {
  if (
    page.path === '/address-management' &&
    restaurant?.location.delivery.kind !== 'available'
  )
    return false
  if (
    page.path === '/credit-card-report' &&
    restaurant?.payment_configs?.credit_card?.payment_processor.kind !== 'adyen'
  )
    return false

  if (
    page.path === '/driver-management' &&
    restaurant?.enabled_delivery_providers.includes('in_house') === false
  ) {
    return false
  }

  return true
}
