import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Box from '@mui/material/Box'
import spacing from '@src/Styles/spacing'
import { colors } from '@src/Styles'
import SuspenseBoundary from '@src/SuspenseBoundary'
import { useUserContext } from '@src/User'
import useSWR, { useSWRConfig } from 'swr'
import { useLingui } from '@lingui/react'
import { useTypographyContext } from '@src/Typography'
import RNumberBanner from '@src/RNumberBanner'
import LayoutNavbar from '@src/Layout/LayoutNavbar'
import { PAGES } from '@src/Layout/helpers'
import Header from '@src/Header'
import LayoutNavDrawer from '@src/Layout/LayoutNavDrawer'
import { useSnackbarContext } from '@src/Snackbar'
import { version } from '@/pages/api/v1/version'
import { t } from '@lingui/core/macro'
import IntercomMessenger from '@src/Intercom'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()
  const scrollToHeaderRef = useRef<HTMLDivElement>(null)
  const { print } = router.query
  const { typography } = useTypographyContext()
  const { i18n } = useLingui()
  const { cache, mutate } = useSWRConfig()
  const { r_number, location, me } = useUserContext()
  const { onError } = useSnackbarContext()

  const isWebView = 'ReactNativeWebView' in window

  const fetcher = (url: string) => fetch(url).then((res) => res.json())

  const { data: { version: serverVersion = '' } = {} } = useSWR(
    '/api/v1/version',
    fetcher,
  )

  const action = useMemo(
    () => ({
      label: t`Reload`,
      onClick: () => window.location.reload(),
    }),
    [],
  )

  useEffect(() => {
    if (serverVersion === '') return

    if (serverVersion !== version) {
      onError(t`Your application is out of date`, action)
    }
  }, [serverVersion, onError, action])

  useEffect(() => {
    if (router.pathname !== '/restaurants') return

    const handleScrollToHeader = () => {
      scrollToHeaderRef.current?.scrollIntoView({ block: 'center' })
    }

    router.events.on('routeChangeComplete', handleScrollToHeader)

    return () => {
      router.events.off('routeChangeComplete', handleScrollToHeader)
    }
  }, [router.events, router.pathname])

  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setIsMobileOpen((prevState) => !prevState)
  }

  useEffect(() => {
    for (const key of cache.keys()) {
      if (key !== '/enterprises') {
        mutate(key, undefined, { revalidate: true })
      }
    }
  }, [r_number, cache, mutate])

  const currentPage = PAGES.filter((page) => {
    if (page.label == null) return false

    return true
  }).find((page) => page.path === router.pathname)

  const rNumberBannerRef = useRef<HTMLDivElement | null>(null)
  const [bannerHeight, setBannerHeight] = useState<number>(0)

  useEffect(() => {
    if ('ReactNativeWebView' in window) {
      try {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'webState',
            payload: {
              user: window.localStorage.getItem('uls:user'),
              r_number,
              wid: me?.wid,
            },
          }),
        )
      } catch (error) {
        console.warn('Failed to send message to ReactNativeWebView:', error)
      }
    }
  }, [r_number, me?.wid])

  const isPrintView = print === 'true'

  const isPublicPage = PAGES.find(
    (page) => page.path === router.pathname,
  )?.isPublic

  if (location == null && !isPublicPage) return null

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        ...typography.body.medium,
      }}
    >
      {!isWebView && !isPrintView && router.pathname !== '/l/[loginToken]' ? (
        <LayoutNavbar handleDrawerToggle={handleDrawerToggle} />
      ) : null}

      <LayoutNavDrawer
        isMobileOpen={isMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box
        component="main"
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          paddingTop: isPrintView
            ? 0
            : isWebView || router.pathname === '/l/[loginToken]'
              ? bannerHeight
              : bannerHeight + spacing.colossal,
          background: colors.primary2[25],
        }}
      >
        {!isPrintView && router.pathname !== '/l/[loginToken]' && (
          <RNumberBanner
            rNumberBannerRef={rNumberBannerRef}
            setBannerHeight={setBannerHeight}
          />
        )}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: spacing.normal,
            width: '100%',
            background: colors.primary2[25],
          }}
        >
          <div css={{ flex: 1 }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                color: colors.neutrals[500],
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  minWidth: 360,
                  maxWidth: 1024,
                  paddingInline: spacing.moderate,
                }}
              >
                <SuspenseBoundary>
                  {
                    <>
                      {!isPrintView && currentPage?.label != null && (
                        <div
                          ref={scrollToHeaderRef}
                          css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: spacing.normal,
                          }}
                        >
                          {currentPage?.showInNavBar && isWebView ? null : (
                            <Header label={i18n._(currentPage?.label)} />
                          )}
                        </div>
                      )}
                      {children}
                      <IntercomMessenger />
                    </>
                  }
                </SuspenseBoundary>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  )
}

export default Layout
