import { useEffect, useState } from 'react'
import { useCountdown } from 'usehooks-ts'
import { useUserContext } from '@src/User'
import { WMagicLinkResponse } from '@kjt01/greendot-wasm'
import { usePost } from '@src/Fetch/helpers'

export const useAuth = ({
  data,
  error,
  hasLoaded,
}: {
  data?: WMagicLinkResponse
  error: boolean
  hasLoaded: React.MutableRefObject<boolean>
}) => {
  const poster = usePost()
  const { setUser, setMe } = useUserContext()

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
  const [time, { startCountdown }] = useCountdown({ countStart: 3 })
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (error == null) return
    setIsError(true)
  }, [error])

  useEffect(() => {
    const handleData = async () => {
      if (hasLoaded.current === true) return

      if (data == null) return

      if ('Ok' in data) return

      hasLoaded.current = true

      try {
        const { token, redirect } = data

        if (token == null) return

        const { token_type } = JSON.parse(
          Buffer.from(token.split('.')[1], 'base64').toString(),
        )

        fetch('/api/v1/me', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ token }),
        })
          .then((res) => res.json())
          .then((res) => {
            if ('Err' in res) {
              throw res
            }
            return res.Ok
          })
          .then((me) => {
            setMe(me)

            setUser({
              token,
              token_type,
            })

            startCountdown()

            setRedirectUrl(redirect ?? '/')
          })
          .catch(() => {
            setIsError(true)
          })
      } catch (error) {
        setIsError(true)
      }
    }

    handleData()
  }, [data, hasLoaded, setUser, setMe, startCountdown, poster])

  return { hasLoaded, redirectUrl, time, isError }
}
