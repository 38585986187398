import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import { useWonders } from '@src/Fetch/helpers'
import { useUserContext } from '@src/User'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { environment } from '@/environment'
import crypto from 'crypto'
import useSWR from 'swr'
import { useCallback } from 'react'

const INTERCOM_VERSION = '2.3.1'

const IntercomMessenger = () => {
  const fetcher = useCallback(
    (url: string) => fetch(url).then((res) => res.json()),
    [],
  )
  const { data: { secretKey } = {} } = useSWR('/api/v1/intercom', fetcher)

  const { cmaIntercomWebMessenger } = useFlags()

  const { me, r_number, user, isAdmin, deviceInfo } = useUserContext()
  const { data: restaurant } = useWonders(
    r_number == null || user.token == null ? null : [`restaurant_info`],
  )

  if (isAdmin || cmaIntercomWebMessenger === false) {
    shutdown()
    return null
  }

  const nativeApplicationVersion =
    window.localStorage.getItem('uls:nativeApplicationVersion') ?? ''
  const userAppVersion =
    nativeApplicationVersion !== ''
      ? JSON.parse(nativeApplicationVersion)
      : null

  // Display web intercom messenger on iOS <15 and android
  if (deviceInfo.name === 'iOS') {
    const majorIOSVersion = deviceInfo.version.split('.')[0]

    if (parseInt(majorIOSVersion) < 15) {
      console.error(
        `IOS VERSION WARNING: user: ${me?.wid} r_number: ${r_number} is on iOS ${deviceInfo.version}`,
      )
    }

    if (parseInt(majorIOSVersion) >= 15 && userAppVersion >= INTERCOM_VERSION) {
      shutdown()
      return null
    }
  }

  if (
    r_number == null ||
    me?.wid == null ||
    me?.phone_number == null ||
    me?.language == null ||
    restaurant == null ||
    user == null ||
    secretKey == null
  )
    return null

  const hash = crypto
    .createHmac('sha256', secretKey)
    .update(me.wid)
    .digest('hex')

  const restaurantName = restaurant.parent_enterprise.name_complex.english_name

  const companies = me.enterprises.flatMap((enterprise) => {
    const name = enterprise.name

    return enterprise.locations.map((location) => ({
      company_id: location.r_number,
      name: `${name} (${location.r_number})`,
    }))
  })

  Intercom({
    app_id: environment === 'production' ? 'tf91g5q4' : 'j1flec5a',
    user_id: me.wid,
    name: `${restaurantName} (${r_number})`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    companies: companies as [any],
    phone: me.phone_number,
    language_override: me.language === 'zh_cn' ? 'zh-CN' : 'en',
    user_hash: hash,
  })

  return null
}

export default IntercomMessenger
