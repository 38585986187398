import { formatter } from '@lingui/format-po'

const config = {
  locales: ['en', 'zh'],
  catalogs: [
    {
      path: '<rootDir>/locales/{locale}',
      include: ['<rootDir>/src'],
      exclude: ['**/node_modules/**'],
    },
  ],
  compileNamespace: 'ts',
  format: formatter({
    lineNumbers: false,
  }),
}

export default config
